button.action-item {
    padding: 0;
    margin-right: 8px;
}

span.page-link.ellipsis-text {
    display: none;
}

#status-fanpage {
    margin-top: 23px;
    margin-left: -35px;
}

button.action-item i {
    font-size: 17px !important;
}

.result_add {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #4dbd74;
}

table.rp tr > td:first-of-type {
    width: 114px;
}

table.rp tr td span {
    margin-left: 11px;
    color: #0250b1;
}

.start-to-count {
    width: 78px;
}

.start-to-count div {
    margin: auto;
}

.rp-body .table-responsive {
    margin-top: -14px;
}

table.rp td {
    border-bottom: 1px solid #c8ced3 !important;
    border-top: none !important;
}

a.profile {
    padding: 10px 24px;
}

#check_status_active {
    position: relative;
    margin-left: 0;
    margin-right: 9px;
}

.btn-actions {
    margin-right: 15px;
}

a.profile {
    padding: 10px 98px 10px 18px;
    display: inline-block;
}

.main_profile {
    padding: 0 !important
}

.table-responsive {
    margin-top: 17px;
}

i.fa.fa-search {
    margin-right: 4px;
}

ul.report-tag {
    list-style: none;
    margin-left: -39px;
}

.main-report-tag {
    margin: 17px;
}

.button-ac a {
    color: #fff !important;
}

.button-ac {
    padding: 0 5px !important;
    color: #fff !important;
    margin-right: 3px;
}

.button-ac i.fa.fa-search {
    margin-right: 0 !important;
}

@media only screen and (max-width: 570px) {
    .searchs {
        margin-bottom: 14px;
    }
}

@media (min-width: 992px) {
    div.modal-lg {
        max-width: 58%;
    }
}

.no-suggestions {
    color: #999;
    padding: 0.5rem;
    position: absolute;
    top: 35px;
    left: 0;
    background-color: #f0f3f5;
    border: 1px solid #e4e7ea;
    width: 300px;
}

.suggestions {
    border: 1px solid #e4e7ea;
    border-top-width: 0;
    list-style: none;
    margin-top: 0;
    max-height: 143px;
    overflow-y: auto;
    padding-left: 0;
    width: 300px;
    position: absolute;
    top: 35px;
    left: 0;
    z-index: 100;
    background-color: #f0f3f5;
}

.suggestions li {
    padding: 0.5rem;
}

.suggestion-active,
.suggestions li:hover {
    cursor: pointer;
    font-weight: 700;
}

.suggestions li:not(:last-of-type) {
    border-bottom: 1px solid #e4e7ea;
}

span#showing_entries {
    display: block;
    float: left;
}

.pagination_main ul.pagination {
    float: right;
}

.help {
    cursor: help;
}

.hear-element {
    margin: 10px 0;
}

.api-input-item {
    margin: 10px 0;
}

.hidden-password {
    float: right;
    margin-left: -25px;
    margin-top: 10px;
    position: relative;
    z-index: 999;
    padding-right: 11px;
    color: #61737f;
    cursor: pointer;
}

.pointer {
    cursor: pointer;
}

.hide {
    display: none;
}

.nav-tabs a.nav-link {
    color: #20a8d8;
}

span.nav-title {
    font-size: 16px;
}

div#modal-head {
    padding-bottom: 0;
    border-bottom: none;
}

h5.modal-title {
    width: 100%;
}

#modal-head.modal-header .close {
    position: absolute;
    top: 5px;
    right: 15px;
    z-index: 99;
}

.upload_file > span {
    position: absolute;
    top: 6px;
    right: -28px;
    font-size: 6px;
}

.upload_file {
    position: relative;
    width: 245px;
}

.upload_file .spinner-border {
    width: 1rem;
    height: 1rem;
}

div#loading_pagename {
    position: absolute;
    right: 29px;
    top: 101px;
}

#loading_pagename .spinner-border {
    width: 1rem;
    height: 1rem;
    font-size: 8px;
}

.companies {
    margin-left: 10px;
}

img.img-avatar-pro {
    margin-right: 15px;
    border: 2px solid #fff;
}

.headpro strong {
    font-size: 19px;
    font-weight: 500;
    text-transform: capitalize;
    color: #fff;
}

.md-list {
    border-bottom: 1px solid rgba(0, 0, 0, .12);
    color: #5f5e5e;
    padding-left: 0 !important;
    padding-bottom: 10px;
}

span.user-detail {
    font-weight: 500;
    font-size: 15px;
}

span.md-f {
    display: block;
}

label.lab-f {
    font-size: 23px;
    padding-left: 4px;
    color: #696363;
}

h4.heading_c {
    font-size: 24px;
    font-weight: 300;
    margin-bottom: 17px;
    margin-top: 17px;
}

.headpro.card-header {
    background-color: #3898fb;
}

.send-load .spinner-border {
    width: 17px;
    height: 17px;
    margin-right: 6px;
}

.send-load > div {
    margin: auto;
    position: relative;
    top: -2px;
    height: 21px !important;
}

.send-load-page {
    width: 33% !important;
    margin: auto;
}

button.m-ladda {
    width: 72px;
}

.send-load {
    width: 46px;
    margin: auto;
}

.dropdownHears {
    height: 200px;
    overflow: scroll;
}

.sidebar .nav-dropdown-items {
    margin-left: 0px !important;
}

.sidebar .nav-dropdown-items .nav-item .nav-link {
    padding-left: 30px;
}

.sidebar .nav-dropdown-items .nav-item .nav-dropdown-items .nav-item .nav-link {
    padding-left: 58px;
    background-color: #232323;
}

.manager {
    color: #3a9d5d;
    font-size: 18px !important;
    cursor: pointer;
}

form#google-reply-form-search {
    margin-bottom: 26px;
    border-bottom: 1px solid #e6e1e1;
}

.contact_supports a {
    color: red;
    font-size: 14px;
}

.email-member {
    color: #3a9d5d;
    margin-right: 7px;
    font-size: 15px !important;
}

strong.contact_support {
    float: right;
}

strong.contact_support a {
    color: red;
}

.has-error {
    color: red;
}

.sidebar .nav-dropdown-items .nav-item .nav-dropdown-items .nav-item .nav-link:hover {
    padding-left: 58px;
    background-color: #20a8d8;
}

.text-center.loading_data {
    position: fixed;
    top: 6px;
    right: 50%;
    z-index: 9999999;
}

#head-form {
    margin: 16px 21px;
}

.date_report {
    margin-top: 21px;
}

.sort_field {
    cursor: pointer;
}

span.icon_sort {
    margin-left: 14px;
}

span.mr-3 {
    width: 48px;
    height: 48px;
    border-radius: 6px;
}

.requires {
    color: red;
    margin-left: 7px;
}

.pagination_main_lb {
    position: relative;
}

.feedLinks {
    padding: 3px;
    font-size: 12px;
    padding-left: 0;
}

.feedCommentForm1 {
    position: relative;
}

.commentInput {
    width: 100%;
    height: 53px;
    border-radius: 4px;
}

a.gg-fullnames {
    color: #000;
}

#updateBox, .commentInput {
    border: solid 1px #999999;
}

.logo-users {
    text-transform: uppercase;
    text-align: center;
    color: #fff;
    font-size: 31px;
}

nav.comment_gg {
    margin-top: 30px;
}

li.page-item.cm_next {
    overflow: hidden;
    float: right;
    position: absolute;
    right: 17px;
    border-left: 1px solid #c8ced3;
    border-radius: 5px;
    bottom: 29px;
}

li.page-item.cm_previous a {
    border-radius: 5px;
}

.media.subcm {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: solid 1px #dedede;
}

.media.main_cm {
    margin-bottom: 16px;
}

.main .container-fluid{
    padding: 0 !important;
}